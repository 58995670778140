const LOGIN_OPTIONS_KEYS = {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    EMAIL: 'email'
};

const LOGIN_OPTIONS = [{
    name: 'Gmail',
    key: LOGIN_OPTIONS_KEYS.GOOGLE,
    icon: 'fab fa-google'
}, /*{
    name: 'Facebook',
    key: LOGIN_OPTIONS_KEYS.FACEBOOK,
    icon: 'fab fa-facebook-f'
},*/ {
    name: 'Twitter',
    key: LOGIN_OPTIONS_KEYS.TWITTER,
    icon: 'fab fa-twitter'
}, {
    name: 'Email',
    key: LOGIN_OPTIONS_KEYS.EMAIL,
    icon: 'fa fa-at',
    tooltip: 'Quickly join using your email'
}];

module.exports = { LOGIN_OPTIONS_KEYS, LOGIN_OPTIONS };